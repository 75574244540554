<template>
    <div class="global_full_screen courseware_item_container">
          <div  class="courseware_item_image">
              <Cover :url="data.cover" :isShowButton="false"></Cover>
          </div>
         <div class="courseware_item_content">
            
            <div class="courseware_item_title">{{data.show_title}}</div>
            <div   class="courseware_item_button_group" @click.stop="" v-if="!isPreview">
              <div class="courseware_item_button" @click.stop="doSelect()" :class="{global_primary_background_color:!isSelect,courseware_item_button_select:isSelect}">
                {{ isSelect?"已选择":"选择"}}
              </div>
            </div>
        </div>
    </div>
</template>
<script>
import Cover from "@/components/unit/Cover"
export default {
    name:"courseware_item",
    props:{
        data:{
            type:Object,
            default:()=>{
                return {}
            }
        },
        index:{
          type:Number,
          default:0
        },
        isSelect:{
          type:Boolean,
          default:false
        },
        isPreview:{
          type:Boolean,
          default:false
        }
    },
    components:{
      Cover
    },
    methods:{
      doSelect(){
        this.$emit("doSelect",this.data)
      }
    },
}
</script>
<style >
    .courseware_item_container{
        padding: 4vw 0;
        margin-bottom: 2vw;
        background: white;
        width:100%;
        word-break: break-all;
        display: flex;
    }
    .courseware_item_content{
        margin-right: 3vw;
        display: flex;
        flex-direction: column;
        flex:1 0 0;
        justify-content: space-between;
    }
    .courseware_item_title{
      flex:1 0 0;
      word-wrap:break-word;
      word-break: break-all;
      font-weight: 700;
      line-height: 6vw;
    }
    .courseware_item_button{
      padding:1vw 6vw;
      border-radius:4vw;
      color:#fff;
    }
    .courseware_item_button_select{
      background:#bfbfbf;
    }
    .courseware_item_button_group{
      display: flex;
      justify-content: flex-end;
    }

    .courseware_item_image{
        width:30vw;
        height: 30vw;
        margin-right:4vw;
        position: relative;
    }

    .courseware_item_image img{
        height: 100%;
        width: 100%;
    }
</style>