<template>
  <div class="coursewares_container" @click="showSelect(false)">
    <div class="coursewares_search">
      <div class="coursewares_search_title">全部课程</div>
      <div class="coursewares_search_block">
        <div class="coursewares_search_label_name" @click.stop="showSelect">
          筛选
          <el-icon>
            <Filter />
          </el-icon>
        </div>
        <div class="coursewares_search_label_ul" v-if="isShowSelect">
          <div
            class="coursewares_search_label_li"
            @click="selectCancers(item)"
            v-for="item in config.cancers"
            :key="item.label"
          >
            {{ item.label }}
          </div>
        </div>
      </div>
    </div>
    <div class="coursewares_label">
      <div
        :class="
          search.cancer
            ? ''
            : 'coursewares_label_name global_primary_text_color'
        "
        v-if="!Boolean(search.cancer)"
      >
        全部
      </div>
      <div v-else class="coursewares_label_cancer_icon">
        <div class="select_cancer_icon global_primary_background_color">
          <img v-if="cancerURL" :src="cancerURL" />
        </div>
        <div class="select_cancer_text global_primary_background_color">
          {{ search.cancer }}
        </div>
      </div>
    </div>
    <CoursewaresScroll
      :url="$urls.admin.courseware"
      :select="search"
      :config="{ isCache: true, prefix: 'coursewares' }"
      ref="ListScroll"
    >
      <template v-slot:listItem="{ citems }">
        <coursewaresItem
          :data="citems"
          @doSelect="doSelect"
          :isPreview="isPreview"
          :isSelect="allUse.includes(citems.id)"
          @click="goToDetail(citems)"
        ></coursewaresItem>
      </template>
    </CoursewaresScroll>
  </div>
</template>

<script>
import CoursewaresScroll from "@/components/unit/CoursewaresScroll";
import { Filter } from "@element-plus/icons";
import coursewaresItem from "@/components/page/admin/coursewaresItem";
export default {
  name: "coursewares",
  data() {
    return {
      config: {
        cancers: [],
      },
      search: {
        cancer: "",
        doctor_id: ""
      },
      allUse: [],
      isShowSelect: false,
      isLoading: false,
      cancerURL: "",
      isPreview: false,
    };
  },
  created() {
    if (!this.isPreview) this.loadClassUse();
    this.isPreview = this.$route.query.preview && this.$route.query.preview != 0;
    this.search.doctor_id = this.$route.query.doctorId || "";
  },
  mounted() {
    this.loadCancers();
  },
  components: {
    CoursewaresScroll,
    Filter,
    coursewaresItem,
  },
  // $route.query.type 0 管理员  1 医生
  methods: {
    loadCancers() {
      this.$axios.get(this.$urls.admin.cancers).then((res) => {
        this.config.cancers = res.data.map((item) => {
          return {
            label: item.cancer,
            cancer: item.img_url,
            value: item.cancer,
          };
        });
        this.config.cancers.unshift({
          label: "全部",
          value: "",
        });
      });
    },
    loadClassUse() {
      let url = this.$tools.getURL(this.$urls.admin.haveCourseware, {
        id: this.$route.query.doctorId,
      });
      this.$axios.get(url).then((res) => {
        this.allUse = res.data.map((x) => x.id);
      });
    },
    doSearch() {
      this.$refs.ListScroll.searchData(this.search);
    },
    showSelect(status) {
      if (typeof status == "boolean") {
        this.isShowSelect = status;
        return;
      }
      this.isShowSelect = !this.isShowSelect;
    },
    doSelect(data) {
      if (this.allUse.includes(data.id) || this.isLoading) {
        this.$tips.error({ text: "每个课件只可选择一次，请选择其他课件" });
        return;
      }
      this.$confirm("课件一旦确认选择，不可更改，请确认是否选择该课件","提示",{
            confirmButtonText: "确定",
            cancelButtonText: "取消",
            center: true,
            type: "warning",
            customClass: "coursewares_confirm_customClass",
        }).then(()=>{
            this.allUse.push(data.id);
            if (this.$route.query.type == 0) {
              let meetingInfo = this.$tools.getLocalCookie("meetingInfo");
              meetingInfo.courseware_id = data.id;
              this.$tools.setLocalCookie("meetingInfo", meetingInfo);
              this.$router.back();
            } else {
              this.doCompleteCourseware(data.id);
            }
        }).catch(() => {});
    },
    doCompleteCourseware(id) {
      this.isLoading = true;
      this.$axios
        .post(this.$urls.admin.bindCourseware, {
          id: this.$route.query.meetId,
          courseware_id: id,
        })
        .then((res) => {
          setTimeout(() => {
            this.isLoading = false;
          }, 1000);
          // this.$router.back()
        })
        .catch((res) => {
          setTimeout(() => {
            this.isLoading = false;
          }, 1000);
        });
    },
    selectCancers(item) {
      this.search.cancer = item.value;
      this.cancerURL = item.cancer;
      this.showSelect();
      this.doSearch();
    },

    goToDetail(citem) {
      // id 课件id, type 0 管理员  1专家， select:是否需要选择，cancer进去后查询的cancer
      this.$router.push({
        path: "/preview",
        query: {
          coursewareId: citem.id,
          ...this.$route.query,
          select: 1,
          ...this.search,
        },
      });
    },
  },
};
</script>

<style>
.coursewares_container {
  width: 100%;
  height: 100%;
  position: relative;
  display: flex;
  flex-direction: column;
  padding: 4vw;
}
.coursewares_search {
  padding: 2vw 0;
  display: flex;
  justify-content: space-between;
}
.coursewares_search_title {
  font-weight: 700;
}
.coursewares_search_block {
  position: relative;
}
.coursewares_label {
  height: 7vw;
}
.coursewares_label_name {
  /* display: inline-block;
      text-align: center; */
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 3vw;
  background: #caebd7;
  font-size: 4vw;
  height: 5.5vw;
  border-radius: 5vw;
  width: 16vw;
}
.coursewares_label_cancer_icon {
  display: flex;
  align-items: center;
  height: 100%;
  position: relative;
}
.select_cancer_icon {
  border-radius: 50%;
  height: 100%;
  width: 7vw;
  z-index: 2;
  display: flex;
  align-items: center;
  justify-content: center;
}
.select_cancer_icon img {
  width: 70%;
  height: 70%;
}
.select_cancer_text {
  height: 80%;
  min-width: 15vw;
  border-radius: 2.5vw;
  color: #fff;
  font-size: 4vw;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0.5vw 2.5vw;
  position: absolute;
  left: 4vw;
}
.coursewares_search_label_name {
  height: 100%;
  font-weight: 700;
  display: flex;
  align-items: center;
  color: #aeaeae;
  font-size: 3.5vw;
}
.coursewares_search_label_ul {
  position: absolute;
  top: 6vw;
  right: 0;
  z-index: 10;
  background: #fff;
  white-space: nowrap;
  padding: 0 0 2vw;
  border: 1px solid #8bc3a1;
  border-radius: 1vw;
  padding: 0 2vw;
  box-shadow: 0vw 0vw 1vw 2px #8bc3a1;
}
.coursewares_search_label_li {
  margin-top: 2vw;
  padding: 2vw;
  border-bottom: 1px solid #9e9e9e;
}
.coursewares_search_label_li:last-child {
  border: none;
}
.coursewares_confirm_customClass {
  width: 90%;
}
.coursewares_confirm_customClass .el-message-box__message,.coursewares_confirm_customClass .el-message-box__title {
  font-size: 4vw;
}
</style>